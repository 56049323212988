@charset 'UTF-8';
@import "/Users/quanbui/website/vhost/tokai-koukoku/src/scss/utility/_media-queries.scss";
@import "/Users/quanbui/website/vhost/tokai-koukoku/src/scss/utility/_mixin.scss";

.page404 {
  .page-heading .jp{
    @include SP{
      letter-spacing: 0.05em;
      padding-left: 50px;
      &:before{width: 36px;}
    }
    @include max-screen(370px){
      font-size: 16px;
      padding-left: 40px;
      &:before{width: 28px;}
    }
  }
  .gr-toppage__jp{
    letter-spacing: 0.02em;
    @include SP{font-size: 18px;}
  }
  .page404-cont{
    padding: 100px 12px 120px;
    text-align: center;
    @include SP{padding: 50px 0 80px;}
    .ttl{
      @include font(50,50/28,0.05,bold);
      @include barlow;
      @include SP{
        font-size: 36px;
        line-height: calc(38/24);
      }
    }
    .txt{
      margin: 40px auto;
      @include font(16,2,0.08em,bold);
      @include SP{margin: 25px auto 40px;}
      @include max-screen(370px){font-size: 14px;}
    }
    .c-btn{
      margin: 0 auto;
    }
  }
}