/* arguments inittial */
$color_body: #002E50;
$color_link: #1558d6;
$color_heading: #dd0000;

$color_bg: #c4c6c6;
$color_bg_secondary: #000000;


$navy: #002E50;
$blue: #0088DB;
$baby_blue: #E7F0F7;

$bg: #F4F7DF;
$neon_green: #9AE02A;
$neon_yellow: #E6FC3C;



$widthcm: 908px;

$font_feature_settings_palt: false;
$font_smooth: true;

$browser_prefix: ("-webkit-", "-moz-", "-ms-", "-o-", "");
@mixin add_prefix($key, $value) {
  @each $prefix in $browser_prefix {
    #{$prefix}$key: $value;
  }
}

/* font style */
@mixin ffN {
  font-family: "ヒラギノ角ゴ Pro", "Hiragino Kaku Gothic Pro", "ヒラギノ角ゴ Pro W3", "ヒラギノ角ゴ W3", "メイリオ", "Osaka", "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
}
@mixin ffM {
  font-family: "ヒラギノ明朝 Pro", "Hiragino Mincho Pro", "HGS明朝E", "ＭＳ Ｐ明朝", serif;
}
@mixin ffYG {
  font-family: "游ゴシック体", "游ゴシック", "YuGothic", "Yu Gothic", "ヒラギノ角ゴ Pro", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, "MS Pゴシック", "MS PGothic", sans-serif;
}
@mixin ffYM {
  font-family: "游明朝体", "游明朝", "YuMincho", "Yu Mincho", "ヒラギノ明朝 Pro", "Hiragino Mincho Pro", "MS P明朝", "MS PMincho", serif;
}
@mixin zen {
  font-family: 'Zen Kaku Gothic New', sans-serif;
}
@mixin barlow {
  font-family: 'Barlow', sans-serif;
}

@mixin thumb{
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  @include add_prefix(backface-visibility,hidden);
  @include transition_c(0.4s ease all);
}

@mixin barlow {
  font-family: 'Barlow', sans-serif;
}

@mixin font-smooth(){
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@mixin off-font-smooth(){
  -webkit-font-smoothing: auto;
  -moz-osx-font-smoothing: auto;
}

@mixin palt(){
  @include add_prefix(font-feature-settings, "palt");
}

/* site style */
@mixin text-hide{
  overflow: hidden;
  text-indent: -9999px;
}


/* [ opacity ]
-------------------------------------------------*/
@mixin opacity($num){
  -ms-filter: alpha(opacity=$num); /* IE8 */
  opacity: $num/100;
  //display: inline-block\9;
}

/* [ display style ]
-------------------------------------------------*/
@mixin display-table{
  display: table;
  > *{
    display: table-cell;
    vertical-align: middle;
  }
}
@mixin height-match{
  display: block;
  text-align: center;
  > *{
    vertical-align: middle;
    display: inline-block;
  }
  &:before{
    content:"";
    height: 100%;
    vertical-align: middle;
    width: 0;
    display: inline-block;
  }
}


/* [ background-image ]
-------------------------------------------------*/
@mixin bgimg($url,$rep:repeat,$pos:left top,$color:none,$size:auto) {
  background-image: url($url);
  background-repeat: $rep;
  background-position: $pos;
  background-color: $color;
  background-size: $size;
}


/* [ writing-mode ]
-------------------------------------------------*/
@mixin writing-mode( $orientation:'h', $direction:'rl' ) {
  @if $orientation == 'v' {
    // Vertical with direction, rl by default
    -ms-writing-mode: tb- + $direction; // IE
    @each $prefix in $browser_prefix {
      #{$prefix}writing-mode: vertical- + $direction;
    }
  } @else {
    // Default...
    -ms-writing-mode: lr-tb; // IE
    @each $prefix in $browser_prefix {
      #{$prefix}writing-mode: horizontal-tb;
    }
  }
}

/* [ easy breakpoint ]
-------------------------------------------------*/
@mixin PC {
  @media (min-width: 768px) {
    @content;
  }
}

@mixin SP {
  @media (max-width: 767px) {
    @content;
  }
}

//aspect-ratio fixed box
//==================================================
@mixin aspect-ratio($width, $height) {
  position: relative;
  &:before {
    display: block;
    content: " ";
    width: 100%;
    padding-top: ($height / $width) * 100%;
  }
  > .content {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
}

//font-size with rem
//==================================================
@mixin font-size($size: 14) {
  font-size: $size + px;
  font-size: ($size / 10) + rem;
}

@mixin clearfix() {
  &:before,
  &:after {
    content: " ";
    display: table;
  }
  &:after {
    clear: both;
  }
}

//==================================================
// Triangle helper mixin
// @param {Direction} $direction - Triangle direction, either `top`, `right`, `bottom` or `left`
// @param {Color} $color [currentcolor] - Triangle color
// @param {Length} $size [1em] - Triangle size
// Call | .css::before { @include triangle(bottom, #000, 5px); }
//==================================================

@mixin triangle($direction, $color: currentcolor, $size: 1em, $distance: 5px) {
  @if not index(top right bottom left, $direction) {
    @error "Direction must be either `top`, `right`, `bottom` or `left`.";
  }

  width: 0;
  height: 0;
  content: "";
  z-index: 2;
  margin: $distance;
  border-#{opposite-position($direction)}: ($size * 1.5) solid $color;

  $perpendicular-borders: $size solid transparent;

  @if $direction == top or $direction == bottom {
    border-left: $perpendicular-borders;
    border-right: $perpendicular-borders;
  } @else if $direction == right or $direction == left {
    border-bottom: $perpendicular-borders;
    border-top: $perpendicular-borders;
  }
}

// SINGLE LINE ELLIPSIS
//==================================================
@mixin singleLineEllipsis() {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

// MULTIPLE LINES ELLIPSIS
//==================================================
@mixin multiLineEllipsis($lineCount: 1) {
  overflow: hidden;
  width: 100%;
  -webkit-line-clamp: $lineCount;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  white-space: normal;
}

// FLEXBOX
//==================================================
@mixin flexbox() {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
}

@mixin flex($values: 1) {
  -webkit-box-flex: $values;
  -moz-box-flex: $values;
  -webkit-flex: $values;
  -ms-flex: $values;
  flex: $values;
}

@mixin flex-wrap($wrap: wrap) {
  -webkit-flex-wrap: $wrap;
  -moz-flex-wrap: $wrap;
  -ms-flex-wrap: $wrap;
  flex-wrap: $wrap;
}

@mixin align-items($align: center) {
  -webkit-align-items: $align;
  -moz-align-items: $align;
  -ms-align-items: $align;
  align-items: $align;
}

@mixin justify-content($direction: center) {
  -webkit-justify-content: $direction;
  -moz-justify-content: $direction;
  -ms-justify-content: $direction;
  justify-content: $direction;
}

@mixin flex-direction($direction: column) {
  -webkit-flex-direction: $direction;
  -moz-flex-direction: $direction;
  -ms-flex-direction: $direction;
  flex-direction: $direction;
}

// ANIMATION KEY-FRAME
//==================================================
@mixin keyframes($content) {
  @-webkit-keyframes #{$content} {
    @content;
  }
  @keyframes #{$content} {
    @content;
  }
}

// FONT
//==================================================
@mixin font($size: "", $lh: "", $letter: "", $fw: "") {
  @if ($size != "") {
    font-size: $size + px;
    font-size: ($size / 10) + rem;
  }
  @if ($lh != "") {
    line-height: $lh;
  }
  @if ($letter != "") {
    letter-spacing: $letter;
  }
  @if ($fw != "") {
    font-weight: $fw;
  }
}
@mixin transition_c ($content, $opt: "") {
  @each $prefix in $browser_prefix {
    @if ($opt == "") {
      #{$prefix}transition: $content;
    } @else {
      #{$prefix}transition-#{$opt}: $content;
    }
  }
}

@mixin transform_c ($content) {
  @each $prefix in $browser_prefix {
    #{$prefix}transform: $content;
  }
}